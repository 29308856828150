import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  options: any;

  constructor() {
  }

  ngOnInit() {
    this.options = {
      center: {lat: 25.4211506, lng: 51.4065722},
      zoom: 12
    };
  }

}
