import { NgtUniversalModule } from '@ng-toolkit/universal';
import { HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {RouterModule} from '@angular/router';
import {APP_BASE_HREF, CommonModule} from '@angular/common';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GMapModule} from 'primeng/gmap';
const route = [
  {path: '', redirectTo: 'Home', pathMatch: 'full'},
  {path: 'Home', component: LandingPageComponent, pathMatch: 'full'},
];

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    HeaderComponent,
    FooterComponent,
    ProjectsListComponent
  ],
  imports:[
 CommonModule,
NgtUniversalModule,
 
 TransferHttpCacheModule,
HttpClientModule,
 
    
    BrowserAnimationsModule,
    RouterModule.forRoot(route),
    GMapModule
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'}
  ],
})
export class AppModule { }
