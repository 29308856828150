<header>
  <div class="collapse bg-dark" id="navbarHeader">
    <div class="container">
      <div class="row">
        <div class="col-sm-8 col-md-7 py-4">
          <h4 class="text-white">About</h4>
          <p class="text-muted">Qatari Constructions & Trading company established in 2016, specialized in high quality buildings like commercial buildings, residential villas and villas complex.</p>
        </div>
        <div class="col-sm-4 offset-md-1 py-4">
          <h4 class="text-white">Contact</h4>
          <ul class="list-unstyled">
            <li><a href="#" class="text-white">Follow on Twitter</a></li>
            <li><a href="#" class="text-white">Like on Facebook</a></li>
            <li><a href="mailto:sales@kigqa.com" class="text-white">Email Us</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar navbar-dark bg-dark shadow-sm">
    <div class="container d-flex justify-content-between">
      <!--<a href="#" class="navbar-brand d-flex align-items-center">-->
        <!--<li class="pi pi-camera"></li>-->
        <!--<strong>&nbsp;Album</strong>-->
      <!--</a>-->
      <a href="https://outlook.office365.com/owa/?realm=kigqa.com" class="navbar-brand d-flex align-items-center" target="_blank">
        <li class="pi pi-envelope"></li><strong>&nbsp;Email Login</strong>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </div>
</header>
