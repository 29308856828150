<main role="main">
  <section class="jumbotron text-center">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <img src="assets/images/site/Logo.png" style="max-width: 70%">
          <h2 class="jumbotron-heading">AlKuwari International Group&nbsp;مجموعة الكواري الدولية</h2>
          <p class="lead text-muted" style="text-align: justify">Qatari Constructions & Trading company established in
            2009, specialized in high quality
            buildings like commercial buildings, residential villas and villas complex</p>
          <p class="lead text-muted">
            شركة تجارة ومقاولات عامة قطرية تأسست سنة 2009 متخصصة في بناء الفلل السكنية والمباني التجارية والمجمعات
            السكنية.
          </p>
          <!--<p>-->
          <!--<a href="#" class="btn btn-primary my-2">Main call to action</a>-->
          <!--<a href="#" class="btn btn-secondary my-2">Secondary action</a>-->
          <!--</p>-->
        </div>
        <div class="col-md-6">
          <h1 class="jumbotron-heading">Locate Us&nbsp;&nbsp;&nbsp;عنواننا</h1>
          <div class="row">
            <div class="col-md-6">
              <div style="line-height: 6px">
                <p class="lead text-muted" style="text-align: justify">Office No. 12</p>
                <p class="lead text-muted" style="text-align: justify">1st Floor</p>
                <p class="lead text-muted" style="text-align: justify">German GYM Building</p>
                <p class="lead text-muted" style="text-align: justify">Um Salal Mohammed Street</p>
                <p class="lead text-muted" style="text-align: justify">+974 77888771</p>
                <p class="lead text-muted" style="text-align: justify">Qatar</p>
              </div>
            </div>
            <div class="col-md-6">
              <div style="line-height: 6px;text-align: justify">
                <p class="lead text-muted" style="text-align: right">مكتب رقم 12</p>
                <p class="lead text-muted" style="text-align: right">الطابق الأول</p>
                <p class="lead text-muted" style="text-align: right">مبنى الجيم الألماني</p>
                <p class="lead text-muted" style="text-align: right">شارع أم صلال محمد</p>
                <p class="lead text-muted" style="text-align: right">+974 77888771</p>
                <p class="lead text-muted" style="text-align: right">قطر</p>
              </div>
            </div>
          </div>
          <hr>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d875.2252762271044!2d51.406820961058585!3d25.422033529737302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf67bd913947d4f7!2sAlKuwari+International+Group!5e0!3m2!1sar!2sqa!4v1549139636971"
            width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </section>
</main>
